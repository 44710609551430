<script lang="ts">
  import { fly } from "svelte/transition";

  import BindSolanaDialog from "./lib/BindSolanaDialog.svelte";

  import { onMount, afterUpdate } from "svelte";
  import tabIconKong from "./assets/tab_icon_kong.png";
  import tabIconRank from "./assets/tab_icon_rank.png";
  import tabIconTask from "./assets/tab_icon_task.png";
  import tabIconAbout from "./assets/tab_icon_about.png";
  import iconEth from "./assets/icon_eth.png";
  import iconSolana from "./assets/icon_solana.png";
  import Kong from "./page/Kong.svelte";
  import Rank from "./page/Rank.svelte";
  import Reward from "./page/Reward.svelte";
  import About from "./page/About.svelte";
  import ConnectModal from "./lib/ConnectModal.svelte";

  import { solanaAddress } from "./store";
  let debug = false;
  let account = "";

  let kong: Kong;
  let activeTab = "Kong";

  let apexNativeBridge: any;
  let JSBridgeCallID = 1;
  let JSBridgeCallbacks: { [key: number]: Function } = {};
  let isInApp = false;
  let getAccountTimer: number;
  let runtime = "";
  let showBindDialog = false;
  let tips = "";

  $: classActiveFont = (tabName: string) => {
    if (tabName === activeTab) {
      return "text-primary font-bold";
    }
    return "text-gray font-bold";
  };

  $: classTabContentOverFlow = () => {
    if (activeTab === "Kong") {
      return "overflow-hidden";
    }
    return "overflow-auto";
  };

  afterUpdate(async () => {
    if (debug) {
      account = "0x7e735CA5C62340406F704Ee02c66B5fFE4ee979b";
    }
  });

  onMount(async () => {
    runtime = "";
    tips = "";
    if (
      (window as any).apexNativeBridge !== undefined ||
      (window as any).flutter_inappwebview !== undefined ||
      debug === true
    ) {
      isInApp = true;
    } else {
      isInApp = false;
      activeTab = "About";
      return;
    }

    tryInitApex();

    if (window.ap === undefined) {
      console.log("window.ap is undefined");
    }

    handleGetAccount();
    await kong.GetScore();
    getAccountTimer = setInterval(() => {
      if (!account) {
        handleGetAccount();
      } else {
        clearInterval(getAccountTimer);
      }
    }, 1000);
  });

  function handleGetAccount() {
    window.ap.call("getUserInfo", {}, (data: any) => {
      if (data.success === true) {
        account = data.data.address;
        fetch("https://api.elerp.net/connect/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: account,
            type: "eth",
            referral: "",
          }),
        });
      }
    });
  }

  async function HandleApexCheckIn() {
    window.ap.call(
      "openPage",
      { url: "apexapp://page/omni/points/signIn" },
      () => {
        fetch(`https://api.elerp.net/apex_checkin/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: account,
            type: "eth",
            referral: "",
          }),
        });
      },
    );
  }

  async function HandleGalxeTask(e: CustomEvent) {
    if (e.detail.task === 1) {
      window.ap.call(
        "openExternal",
        { url: "https://app.galxe.com/quest/apexprotocol/GCHtUtkk5U" },
        () => {},
      );
    } else if (e.detail.task === 2) {
      window.ap.call(
        "openExternal",
        { url: "https://app.galxe.com/quest/apexprotocol/GCHtUtkk5U" },
        () => {},
      );
    } else if (e.detail.task === 3) {
      window.ap.call(
        "openExternal",
        { url: "https://app.galxe.com/quest/apexprotocol/GCcmhtkMga" },
        () => {},
      );
    }
  }

  async function HandleAboutClick(e: CustomEvent) {
    if (e.detail.item === 1) {
      window.ap.call(
        "openExternal",
        { url: "https://pro.apex.exchange/activity/m/staking/stake" },
        () => {},
      );
    } else if (e.detail.item === 2) {
      window.ap.call(
        "openPage",
        { url: "apexapp://page/omni/main/home" },
        () => {},
      );
    } else if (e.detail.item === 3) {
      activeTab = "Kong";
    }
  }

  function handleConnectWallet() {
    window.ap.call("connectWallet", {}, (data: any) => {
      handleGetAccount();
    });
  }

  function handleConnectEvent() {
    handleConnectWallet();
  }

  function handleConnectSolana() {
    showBindDialog = true;
  }

  function handleBindSolana(event: CustomEvent) {
    const solanaAddress = event.detail.solanaAddress;

    const message = JSON.stringify({
      operation: "account",
      ethAddress: account.toLowerCase(),
      solanaAddress: solanaAddress,
    });

    window.ap.call("personalSign", { message: message }, (data: any) => {
      if (data.success === true) {
        fetch("https://api.elerp.net/bind_solana/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: message,
            signature: data.data.signature,
            client: "apex",
          }),
        }).then((res) => {
          if (res.status === 200) {
            $solanaAddress = solanaAddress;
            tips = "";
            showBindDialog = false;
          } else if (res.status === 400) {
            tips = "The Solana address you entered is incorrect.";
          } else if (res.status === 401) {
            tips = "The signature or eth address is incorrect,please retry.";
          } else if (res.status === 409) {
            tips =
              "This Solana address is already registered. Please enter a different address.";
          } else {
            tips = "Unknown error, please retry.";
          }
        });
      }
    });
  }

  function tryInitApex() {
    if (window.ap) {
      return;
    }
    window.ap = {
      call: (bridgeName: string, data: any, callback: Function) => {
        let thisId = JSBridgeCallID++;
        JSBridgeCallbacks[thisId] = callback;
        const swapMessage = JSON.stringify({
          bridgeName: bridgeName,
          data: data || {},
          callbackId: thisId,
        });
        if ((window as any).flutter_inappwebview) {
          (window as any).flutter_inappwebview.callHandler(
            "apexNativeBridge",
            swapMessage,
          );
          runtime = "apex";
        } else {
          if (!(window as any)?.apexNativeBridge) return;
          apexNativeBridge?.postMessage(swapMessage);
          runtime = "apex";
        }
      },
      onReceive: (msg: any) => {
        const callbackId = msg.callbackId;
        const data = msg.data || {};
        if (callbackId) {
          if (JSBridgeCallbacks[callbackId]) {
            JSBridgeCallbacks[callbackId](data);
            delete JSBridgeCallbacks[callbackId];
          }
        }
      },
    };
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="flex justify-around h-screen select-none bg-[#010302] overflow-hidden"
>
  <div class="flex flex-col h-screen max-w-lg w-screen relative">
    <div class="grow w-full h-screen {classTabContentOverFlow()}">
      {#if activeTab === "Kong"}
        <Kong bind:this={kong} {account} />
      {/if}
      {#if activeTab === "Rank"}
        <Rank />
      {/if}
      {#if activeTab === "Reward"}
        <Reward
          {account}
          on:ApexCheckIn={HandleApexCheckIn}
          on:GalxeTaskClick={HandleGalxeTask}
        />
      {/if}
      {#if activeTab === "About"}
        <About {isInApp} on:AboutClick={HandleAboutClick} />
      {/if}
    </div>
    {#if isInApp}
      <div
        class="flex flex-row w-full bg-[#1F1F1F] h-16 border-t-2 border-[#343434] justify-center"
      >
        <div
          class="grow flex flex-row justify-around content-center items-center"
          on:click={() => {
            activeTab = "Kong";
          }}
        >
          <div class="flex flex-col justify-around content-center items-center">
            <div class="w-6 h-6">
              <img src={tabIconKong} alt="tabIconKong" />
            </div>
            <p class="font-popins {classActiveFont('Kong')} text-xs">Kong</p>
          </div>
        </div>
        <div
          class="grow flex flex-row justify-around content-center items-center"
          on:click={() => {
            activeTab = "Rank";
          }}
        >
          <div class="flex flex-col justify-around content-center items-center">
            <div class="w-6 h-6">
              <img src={tabIconRank} alt="tabIconRank" />
            </div>
            <p class="font-popins {classActiveFont('Rank')} text-xs">Rank</p>
          </div>
        </div>
        <div
          class="grow flex flex-row justify-around content-center items-center"
          on:click={() => {
            activeTab = "Reward";
          }}
        >
          <div class="flex flex-col justify-around content-center items-center">
            <div class="w-6 h-6">
              <img src={tabIconTask} alt="tabIconTask" />
            </div>
            <p class=" font-popins {classActiveFont('Reward')} text-xs">
              Tasks
            </p>
          </div>
        </div>
        <div
          class="grow flex flex-row justify-around content-center items-center"
          on:click={() => {
            activeTab = "About";
          }}
        >
          <div class="flex flex-col justify-around content-center items-center">
            <div class="w-6 h-6">
              <img src={tabIconAbout} alt="tabIconAbout" />
            </div>
            <p class="font-popins {classActiveFont('About')}  text-xs">About</p>
          </div>
        </div>
      </div>
      <div
        class="absolute flex flex-row justify-end items-center bg-[#00060300] h-16 w-screen max-w-lg z-50 mt-4"
        class:hidden={activeTab !== "Kong"}
      >
        {#if account}
          <div class="flex flex-col justify-start">
            <div
              class="h-9 w-32 mr-3 bg-[#00000080] rounded-full flex justify-around content-center items-center"
            >
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src={iconEth} class="h-5 w-5" />
              <p class="font-popins text-xs text-[#FFFFFF] font-normal">
                {`${account.substring(0, 6)}...${account.substring(account.length - 6)}`}
              </p>
            </div>
            {#if $solanaAddress === "" || $solanaAddress === undefined || $solanaAddress === null}
              <div
                class="h-9 w-32 mt-3 bg-white rounded-full border-[2px] border-[#525252] flex flex-col justify-around content-center items-center text-center"
                on:click={handleConnectSolana}
              >
                <p
                  class="font-popins text-[12px] text-black font-semibold w-full"
                >
                  Connect Wallet
                </p>
              </div>
            {:else}
              <div class="flex flex-col justify-start mt-3">
                <div
                  class="h-9 w-32 mr-3 bg-[#00000080] rounded-full flex justify-around content-center items-center"
                >
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <img src={iconSolana} class="h-5 w-5" />
                  <p class="font-popins text-xs text-[#FFFFFF] font-normal">
                    {`${$solanaAddress.substring(0, 6)}...${$solanaAddress.substring($solanaAddress.length - 6)}`}
                  </p>
                </div>
              </div>
            {/if}
          </div>
        {/if}
        {#if !account}
          <div
            class="h-9 w-32 mr-3 bg-primary rounded-full border-[2px] border-[#525252] flex flex-col justify-around content-center items-center"
            on:click={handleConnectWallet}
          >
            <p class="font-popins text-xs text-black font-bold">
              Connect Wallet
            </p>
          </div>
        {/if}
      </div>
      {#if !account}
        <div
          class="absolute h-full w-full bg-[#000000] opacity-85 z-0 flex content-center items-center justify-center"
        ></div>
        <div
          class="absolute h-full w-full z-40 flex content-center items-center justify-center"
        >
          <ConnectModal on:connectEvent={handleConnectEvent} />
        </div>
      {/if}
      {#if showBindDialog}
        <div
          class="absolute top-0 left-0 w-full h-full bg-[#000000DD] z-50"
        ></div>
        <div class="z-50" transition:fly={{ delay: 0, duration: 300, y: 384 }}>
          <BindSolanaDialog
            {tips}
            on:close={() => {
              showBindDialog = false;
            }}
            on:bindSolana={(event) => handleBindSolana(event)}
          ></BindSolanaDialog>
        </div>
      {/if}
    {/if}
  </div>
</div>
