<script lang="ts">
  import baby_kong from "../assets/baby_kong.png";
  import teen_kong from "../assets/teen_kong.png";
  import master_kong from "../assets/master_kong.png";

  import { score } from "../store";
  import { baby_grow } from "../store";
  import { teen_grow } from "../store";

  let progress_baby_neg = 100;
  let progress_teen_neg = 100;
  $: {
    if ($score < $baby_grow) {
      progress_baby_neg = 100 - ($score / $baby_grow) * 100;
    } else {
      progress_baby_neg = 0;
    }
  }
  $: {
    if ($score < $baby_grow) {
      progress_teen_neg = 100;
    } else if ($score < $teen_grow) {
      progress_teen_neg = 100 - (($score - $baby_grow) / $teen_grow) * 100;
    } else {
      progress_teen_neg = 0;
    }
  }
</script>

<div
  class="flex flex-col items-center justify-between w-10 h-[9.5rem] border-[#555555] bg-[#11000080] rounded-full"
>
  <img src={master_kong} alt="" class="block w-8 h-8 mt-1" />
  <img src={teen_kong} alt="" class="w-8 h-8" />
  <img src={baby_kong} alt="" class="w-8 h-8 mb-1" />
  <div class="absolute top-9 left-[1.125rem] w-1 h-6 z-30 bg-primary">
    <div class="bg-[#000000] w-full" style="height: {progress_teen_neg}%"></div>
  </div>
  <div class="absolute bottom-9 left-[1.125rem] w-1 h-6 bg-primary">
    <div class="bg-[#000000] w-full" style="height: {progress_baby_neg}%"></div>
  </div>
</div>
