<script lang="ts">
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import iconClose from "../assets/icon_close.png";
  export let tips = "";
  let bindButtonDisable = false;
  let solanaAddress = "";

  // onMount(async () => {
  //   tips = "";
  // });
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->

<div
  class="absolute bottom-0 min-h-[24rem] left-0 z-50 w-full bg-[#FFF9D8] rounded-t-3xl flex flex-col p-4 shadow-t-xl"
>
  <div class="w-full h-5 flex justify-between content-center items-center">
    <p class="font-popins font-bold text-[1.125rem]">Connect Solana Wallet</p>

    <img
      src={iconClose}
      alt="iconClose"
      class="w-8 h-8"
      on:click={() => {
        dispatch("close");
      }}
    />
  </div>
  <div class="my-4 bg-[#FA8B0714] rounded-xl">
    <p class="font-popins text-[#FA8A06] text-[0.9rem]">
      Your $KONG airdrop will be sent to this Solana wallet. Please provide your
      address before the airdrop distribution begins.
    </p>
  </div>
  <div class="">
    <p class="font-popins font-bold text-[1.125rem]">
      Enter and confirm your Solana wallet address.
    </p>
    <input
      type="text"
      class="w-full h-[3rem] mt-2 bg-white rounded-xl border-[1px] font-popins text-center"
      bind:value={solanaAddress}
    />
    {#if tips !== ""}
      <div class="mt-2">
        <p class="font-popins text-[#EB1C1C] text-xs">
          {tips}
        </p>
      </div>
    {/if}

    <button
      class="w-full h-[3rem] mt-3 rounded-xl {bindButtonDisable
        ? 'bg-[#525252]'
        : 'bg-black'}"
      disabled={bindButtonDisable}
      on:click={() => {
        dispatch("bindSolana", { solanaAddress });
      }}
    >
      <p class="font-popins text-white text-[1.125rem]">Confirm</p>
    </button>
  </div>
</div>
