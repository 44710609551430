<script lang="ts">
  import game_bg from "../assets/game_bg.png";
  import bananaIcon from "../assets/banana.png";
  import { score } from "../store";
  import { banana } from "../store";
  import { bananaLimit } from "../store";
</script>

<div
  class="min-h-20 max-h-20 bg-[#0C0C0C] flex flex-row justify-center content-between items-center"
>
  <div class="flex-col grow">
    <div>
      <p class="font-popins font-normal text-white text-center text-sm h-5">
        Feed Your Kong:Daily Quota
      </p>
    </div>
    <div class="content-center text-center items-center">
      <p class=" inline font-popins font-extrabold italic text-white text-base">
        {$banana.toLocaleString()}/{$bananaLimit.toLocaleString()}
      </p>
      <img src={bananaIcon} alt="banana" class="inline w-6 h-6" />
    </div>
  </div>
  <div class="border-r-[1px] h-16 w-[1px] mt-2 border-[#555555]"></div>
  <div class="flex-col grow">
    <div>
      <p class="font-popins font-normal text-white text-center text-sm h-5">
        Feed-O-Meter
      </p>
    </div>
    <div class=" content-center text-center items-center">
      <p class=" inline font-popins font-extrabold italic text-white text-base">
        {$score.toLocaleString()}
      </p>
      <img src={bananaIcon} alt="banana" class="inline w-6 h-6" />
    </div>
  </div>
</div>
