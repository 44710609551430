<script lang="ts">
  import banana_reward from "../assets/banana_reward.png";
  import icon_help from "../assets/icon_help.png";
  export let bannerTopic;
  let tipsShow = false;

  function toggleTipsShow() {
    tipsShow = !tipsShow;
  }

  function handleTipsOff() {
    tipsShow = false;
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="relative flex-col mt-4 z-40">
  <div class="relative flex justify-between z-30">
    <div class="h-7 w-[3.75rem]"></div>
    <div class="flex grow bg-primary h-[1.75rem] rounded-t-2xl">
      <div
        class="h-[1.75rem] w-[1.25rem] bg-primary rounded-t-2xl"
        style="transform: skew(-15deg);"
      ></div>
      <div class="h-[1.75rem] grow flex justify-start items-center">
        <p class="font-popins italic text-sm font-extrabold">
          {bannerTopic}
        </p>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <img
          src={icon_help}
          alt="icon_help"
          class="h-4 w-4 ml-2"
          on:click={toggleTipsShow}
        />
      </div>
    </div>
  </div>
  <div class="relative bg-primary w-full rounded-b-2xl rounded-l-2xl z-30">
    <slot></slot>
  </div>
  <div class="absolute top-0 left-0 px-[0.375rem] py-1 w-full h-full z-10">
    <div class="px-1 py-2 bg-[#414141] h-full w-full rounded-xl"></div>
  </div>
  <div class="absolute top-[-0.75rem] left-4 z-20 h-10 w-8">
    <img src={banana_reward} alt="banana_reward" />
  </div>
  {#if tipsShow}
    <div
      class="p-3
      absolute top-[-12.25rem] left-4
      w-60 h-48
      bg-white rounded-2xl z-50
      flex content-center justify-center items-center"
    >
      <p class="font-popins text-base font-bold">
        The following tasks are to be completed on the GALXE platform. Please
        note that task completion data will be updated every 3~5 days.
      </p>
      <div
        class="absolute -bottom-3 left-[6rem] w-0 h-0 border-x-[1rem] border-x-transparent border-t-[1rem] border-t-white"
      ></div>
    </div>
  {/if}
</div>
