<script lang="ts">
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
  import GalxeTaskPanel from "../lib/GalxeTaskPanel.svelte";
  import DailyTaskPanel from "../lib/DailyTaskPanel.svelte";
  import RewardItem from "../lib/RewardItem.svelte";
  import banner_reward from "../assets/banner_reward.png";
  import icon_daily from "../assets/icon_daily.png";
  import icon_social from "../assets/icon_social.png";
  import icon_msg from "../assets/icon_msg.png";
  import icon_link from "../assets/icon_link.png";
  export let account: string | undefined;

  let task: string[] = [];
  let dailyTaskDone = false;
  let fetchTaskTimer: number;
  const dispatch = createEventDispatcher();

  async function onApexDailyCheckInClick() {
    dispatch("ApexCheckIn");
  }

  async function onGalxeTaskClick(task: number) {
    dispatch("GalxeTaskClick", { task: task });
  }

  function fetchTask() {
    fetch(`https://api.elerp.net/task/${account}?type=eth`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        dailyTaskDone = data["dailyTask"]["done"];
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  onMount(() => {
    fetchTask();
    fetchTaskTimer = setInterval(fetchTask, 3000);
    // window.scrollTo(0, 0);
  });

  onDestroy(() => {
    if (fetchTaskTimer) clearInterval(fetchTaskTimer);
  });
</script>

<div class="flex-col content-start justify-start items-cente px-4 pb-4">
  <div>
    <img src={banner_reward} alt="banner_reward" />
  </div>
  <DailyTaskPanel bannerTopic={"Daily Task"}>
    <RewardItem
      rewardType=""
      discription="Daily ApeX Social Check-In"
      rewardScore="2000"
      done={dailyTaskDone}
      icon={icon_daily}
      on:click={onApexDailyCheckInClick}
    ></RewardItem>
  </DailyTaskPanel>
  <GalxeTaskPanel bannerTopic={"Task"}>
    <RewardItem
      rewardType="One Time Tasks"
      discription="Join our social & community channels"
      rewardScore="8000"
      done={task[1]}
      icon={icon_social}
      on:click={() => onGalxeTaskClick(1)}
    ></RewardItem>
    <RewardItem
      rewardType="Limited Time Tasks"
      discription="Interact with our daily announcements"
      rewardScore="1000"
      done={task[2]}
      icon={icon_msg}
      on:click={() => onGalxeTaskClick(2)}
    ></RewardItem>
    <RewardItem
      rewardType="Invite Friends"
      discription="Refer your friends to ApeX"
      rewardScore="6200"
      done={task[3]}
      icon={icon_link}
      on:click={() => onGalxeTaskClick(3)}
    ></RewardItem>
  </GalxeTaskPanel>
</div>
