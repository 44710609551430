<script lang="ts">
  import { afterUpdate, onMount, onDestroy } from "svelte";
  import { fade, fly } from "svelte/transition";
  import GrowProcessBar from "../lib/GrowProcessBar.svelte";
  import ScoreBoard from "../lib/ScoreBoard.svelte";
  import Game from "../lib/Game.svelte";
  import game_bg from "../assets/game_bg.png";
  import bush from "../assets/bush.png";
  import eatAudio from "../assets/eat.mp3";

  import iconSoundOn from "../assets/icon_sound_on.png";
  import iconSoundOff from "../assets/icon_sound_off.png";
  import iconBigBanana from "../assets/icon_big_banana.png";
  import localForage from "localforage";

  import ToastWarning from "../lib/ToastWarning.svelte";

  import { score } from "../store";
  import { baby_grow } from "../store";
  import { teen_grow } from "../store";
  import { limited } from "../store";
  import { banana } from "../store";
  import { bananaLimit } from "../store";
  import { solanaAddress } from "../store";

  export let account: string | undefined = undefined;

  let linked = false;
  let game: Game;
  let sound = true;

  let dots: any[] = [];
  let quickHit = 0;
  let quickHitTimer: any;
  let clickTipsColldowntimer: number | null = null;
  let clickTipsColldown = true;
  let active = false;
  let getScoreTimer: number;

  let initKong = false;

  export function setAccount(_account: string) {
    account = _account;
  }

  $: {
    console.log(account);
    if (account) {
      GetScore();
    }
  }

  onMount(async () => {
    initKong = false;
    active = true;
    await GetScore();
    // await game.setGameState();
    const response = await fetch(eatAudio);
    const blob = await response.blob(); // 将响应体转换为 Blob
    await localForage.setItem("eatSound", blob);
    getScoreTimer = setInterval(async () => {
      if ($limited || clickTipsColldown) await GetScore();
    }, 2200);
  });

  // afterUpdate(async () => {
  //   console.log("afterUpdate");
  //   await GetScore();
  //   // game.setGameState();
  // });

  onDestroy(() => {
    active = false;
    if (getScoreTimer) clearInterval(getScoreTimer); // 销毁定时器
  });
  async function playEatSound() {
    const blob: MediaSource | Blob | null =
      await localForage.getItem("eatSound");
    if (blob) {
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);
      audio.play();
    }
  }

  const handleFeed = async (event: any) => {
    if ($limited) {
      return;
    }
    {
      clickTipsColldown = false;
      if (clickTipsColldowntimer) {
        clearTimeout(clickTipsColldowntimer);
      }
      clickTipsColldowntimer = setTimeout(async () => {
        clickTipsColldown = true;
        await GetScore();
      }, 6000);
    }

    {
      quickHit++;
      if (quickHitTimer) {
        clearTimeout(quickHitTimer);
      }
      quickHitTimer = setTimeout(() => {
        quickHit = 0;
      }, 1000);
      const x = event.pageX;
      const y = event.pageY;
      const dot = { x, y, id: Math.random(), visible: true, click: quickHit };
      dots = [...dots, dot];
      setTimeout(() => {
        dots = dots.filter((d) => d.id !== dot.id);
      }, 2000);
      setTimeout(() => {
        dots = dots.map((dot) => {
          if (dot.id === dot.id) {
            return { ...dot, visible: false };
          } else {
            return dot;
          }
        });
      }, 200);
    }

    {
      // score++;
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const referral = params.get("referral");
      fetch(`https://api.elerp.net/feed`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
        body: JSON.stringify({
          address: account,
          type: "eth",
          referral: "",
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let _score = parseInt(data["score"], 10);
          if (_score >= 0) {
            score.update((v) => {
              if (v !== _score) {
                return _score;
              } else {
                return v;
              }
            });
            baby_grow.set(parseInt(data.baby_grow));
            teen_grow.set(parseInt(data.teen_grow));
            limited.set(data.$limited);
            banana.set(parseInt(data.banana));
            bananaLimit.set(parseInt(data.banana_limite));
            solanaAddress.set(data.solana_address);
          } else {
            limited.set(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    game.handleFeed($score);
    if (sound) {
      playEatSound();
    }
  };

  export async function GetScore() {
    if (!account) return;
    await fetch(`https://api.elerp.net/score/${account}?type=eth`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let _score = parseInt(data["score"], 10);
        if (_score >= 0) {
          score.set(_score);
          baby_grow.set(parseInt(data.baby_grow));
          teen_grow.set(parseInt(data.teen_grow));
          limited.set(data.limited);
          banana.set(parseInt(data.banana));
          bananaLimit.set(parseInt(data.banana_limite));
          solanaAddress.set(data.solana_address);

          if (_score === 0 && initKong) {
            game.setGameState();
            initKong = true;
          }
          if (!linked) {
            linked = true;
          }
        } else {
          limited.set(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<div
  class="relative flex flex-col justify-end h-full bg-[#010302] overflow-hidden"
>
  <div class="relative grow">
    <div class="absolute bottom-0 z-0">
      <img src={game_bg} alt="game_bg" class="w-full" />
    </div>
    <div class="absolute bottom-0 z-40">
      <img src={bush} alt="bush" class="w-full" />
    </div>
    {#if account}
      <div class="absolute bottom-0 z-30 w-full h-full">
        <Game bind:this={game} />
      </div>
    {/if}
    <div
      class="absolute bottom-0 z-40 h-full w-full"
      on:click={handleFeed}
    ></div>
    {#if $limited}
      <ToastWarning message="Kong finished the banana!" color="bg-[#FE5C0F]" />
    {/if}
    {#if clickTipsColldown && !$limited && account}
      <ToastWarning message="Click to feed!" color="bg-primary" />
    {/if}
  </div>
  <ScoreBoard />

  <!-- absolute weiget -->
  {#if account}
    <div class="absolute z-30 top-[9.75rem] right-[0.75rem]">
      <GrowProcessBar />
    </div>
    <div
      class="absolute flex justify-center items-center z-40 top-[6.75rem] right-[0.75rem] h-10 w-10 bg-[#11000080] rounded-full"
      on:click={() => {
        sound = !sound;
      }}
    >
      {#if sound}
        <img src={iconSoundOn} alt="icon_sound_on" class="w-8 h-8" />
      {/if}
      {#if !sound}
        <img src={iconSoundOff} alt="icon_sound_on" class="w-8 h-8" />
      {/if}
    </div>
  {/if}

  {#each dots as { x, y, id, visible, click } (id)}
    {#if visible === true}
      <div
        class="absolute z-50 flex"
        style="left: {x - 10}px; top: {y - 32}px;"
        out:fly={{
          y: -135,
          duration: 1000,

          opacity: 0.1,
        }}
        in:fade
        {id}
      >
        <img src={iconBigBanana} alt="iconBigBanana" class="w-10 h-12" />
        <p
          class="text-primary italic text-click text-2xl font-extrabold text-outline"
        >
          +{click}
        </p>
      </div>
    {/if}
  {/each}
</div>

<style>
  .text-outline {
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000,
      0px -2px 0 #000,
      0px 2px 0 #000,
      -2px 0px 0 #000,
      2px 0px 0 #000;
  }
</style>
