<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import kong_angle from "../assets/kong_angle.png";
  import fire from "../assets/fire.gif";
  import firewood from "../assets/firewood.png";
  import card_a from "../assets/card_a.png";
  import card_b from "../assets/card_b.png";
  import card_c from "../assets/card_c.png";
  import forest_bg_top from "../assets/forest_bg_top.png";
  import forest_bg_bottom from "../assets/forest_bg_bottom.png";
  import kong_pi from "../assets/kong_pi.png";
  import share_1 from "../assets/share_1.png";
  import share_2 from "../assets/share_2.png";
  import share_3 from "../assets/share_3.png";
  import finger from "../assets/finger.png";
  import icon_x from "../assets/icon_x.png";
  import icon_tel from "../assets/icon_tel.png";
  import icon_dis from "../assets/icon_dis.png";
  import contract from "../assets/contract.png";
  import download from "../assets/download.png";
  export let isInApp = false;
  const dispatch = createEventDispatcher();

  async function onClick(item: number) {
    dispatch("AboutClick", { item: item });
  }
</script>

<div class="flex-col content-start justify-start items-center bg-[#1F1F1F]">
  {#if isInApp}
    <div class="mt-16"></div>
  {/if}
  {#if !isInApp}
    <div>
      <a href="https://www.apex.exchange/download" target="_blank">
        <img src={download} alt="download" class="" />
      </a>
    </div>
  {/if}
  <div class="flex pt-6 text-center justify-center">
    <p class="font-heyaugust text-white text-2xl">Join the KONG Revolution</p>
  </div>
  <div class="flex mt-6 text-center justify-center">
    <img src={kong_angle} alt="kong_angle" />
  </div>
  <div class="flex mt-6 text-center justify-center mx-5">
    <p class="font-heyaugust text-white text-sm w-80">
      Deep in the heart of the crypto jungle, where fortunes are forged and
      legends are born, KONG reigns supreme.
    </p>
  </div>
  <div class="flex mt-6 text-center justify-center mx-5">
    <p class="font-heyaugust text-white text-sm w-80">
      Inspired by the mighty King Kong and forged in the fires of the ApeX
      Protocol, KONG is not just a meme token; it’s a movement. A movement where
      every ape can build wealth, grow stronger, and climb to the top of the
      crypto kingdom.
    </p>
  </div>

  <div
    class="relative flex flex-col mt-6 text-center justify-center content-center items-center"
  >
    <img src={fire} alt="fire" class=" w-12 h-12 mt-8 z-50" />
    <img
      src={firewood}
      alt="firewood"
      class="w-12 mt-[-12px] lg:w-[128px] lg:mt-[-32px] z-50"
    />

    <div
      class="bg-[#333333] w-full z-40 flex content-center items-center justify-center"
    >
      <p class="font-heyaugust text-white text-2xl w-52 pt-3 pb-8 z-50">
        In the KONG Kingdom, we believe in community, strength, and honor.
      </p>
    </div>
    <img src={card_a} alt="card_a" class="px-5 pt-3 z-50 bg-[#333333]" />
    <img src={card_b} alt="card_b" class="px-5 pt-3 z-50 bg-[#333333]" />
    <img src={card_c} alt="card_c" class="px-5 pt-3 z-50 bg-[#333333] mb-20" />

    <img
      src={forest_bg_top}
      alt="forest_bg_top"
      class=" absolute top-0 left-0 w-full z-0"
    />
    <img
      src={forest_bg_bottom}
      alt="forest_bg_bottom"
      class=" absolute bottom-0 left-0 w-full z-0"
    />
  </div>

  <div class="flex-col items-start px-5 mt-6">
    <p class="font-heyaugust text-white text-2xl">Get Your Cut of the Jungle</p>
    <p class="font-heyaugust text-primary text-2xl mt-2">KONG Airdrop</p>
    <p class="font-heyaugust text-white text-sm w-60 mt-4">
      10% of the total supply, airdropped straight to the community's doorstep
    </p>
    <img src={kong_pi} alt="kong_pi" class=" relative top-[-0.75rem]" />
  </div>
  <div class="flex-col content-start items-center px-5 mt-6">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click={() => onClick(1)}>
      <img src={share_1} alt="share_1" class="mt-1" />
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click={() => onClick(2)}>
      <img src={share_2} alt="share_2" class="mt-1" />
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click={() => onClick(3)}>
      <img src={share_3} alt="share_3" class="mt-1" />
    </div>
    <img src={finger} alt="finger" class="mt-8 mb-16" />
    <div
      class="w-42 h-24 flex flex-col content-center items-center justify-between"
    >
      <p class="font-popins text-base italic font-bold text-white">
        Enter the Jungle.
      </p>
      <p class="font-popins text-base italic font-bold text-white">
        Grow KONG.
      </p>
      <p class="font-popins text-base italic font-bold text-white">
        Lead with Power.
      </p>
    </div>
    <div class="flex w-42 content-center justify-center items-center mt-9">
      <a href="https://x.com/OfficialApeXdex" target="_blank">
        <img src={icon_x} alt="" class="h-9 w-9" />
      </a>
      <a href="https://t.me/ApeXdex" target="_blank">
        <img src={icon_tel} alt="" class="h-9 w-9 mx-5" />
      </a>
      <a href="https://discord.gg/apexprotocol" target="_blank">
        <img src={icon_dis} alt="" class="h-9 w-9" />
      </a>
    </div>
    <div
      class="flex w-42 content-center justify-center items-center mt-9 mb-12"
    >
      <a href="https://www.apex.exchange/" target="_blank">
        <img
          src={contract}
          alt=""
          class="h-[60px] w-[162px] mr-[8px] lg:mr-0"
        />
      </a>
    </div>
  </div>
  {#if isInApp}
    <div class="pb-8"></div>
  {/if}
</div>
