<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import icon_daily from "../assets/icon_daily.png";
  import icon_done from "../assets/icon_done.png";
  export let rewardType;
  export let discription;
  export let rewardScore;
  export let done;
  export const onClick = () => {};
  export let icon;
  const dispatch = createEventDispatcher();

  function handleClick() {
    dispatch("click", { message: "Clicked!" });
  }
  onMount(() => {});
</script>

<div class="px-[0.4rem] py-[0.4rem]">
  <div class="flex bg-[#FFF9D8] py-4 px-3 items-center rounded-xl">
    <img src={icon} alt="icon_daily" class="h-6 w-6 mr-3" />
    <div class="h-6 border-r-[1px] border-[#000000] opacity-25 mr-3"></div>
    <div class="flex justify-between items-center grow">
      <div class="flex flex-col max-w-48">
        <p class="font-popins text-sm font-bold">
          {rewardType}
        </p>
        <p class="font-popins text-xs">
          {discription}
        </p>
        <p class="font-popins text-lg font-bold">🍌+{rewardScore}</p>
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      {#if done === "true" || done === true}
        <div
          class=" text-primary font-popins text-sm h-8 w-16 flex justify-center items-center rounded-full"
        >
          <img src={icon_done} alt="icon_done" class="h-4 w-4 mr-1" />
          <p class="text-primary font-popins text-sm">Done</p>
        </div>
      {:else}
        <div
          class="bg-[#000000] text-[#FFDC83] font-popins text-sm font-medium h-8 w-16 flex justify-center items-center rounded-full"
          on:click={handleClick}
        >
          GO
        </div>
      {/if}
    </div>
  </div>
</div>
