import { writable } from "svelte/store";
interface UserScore {
    account: string;
    score: number;
}

export const score = writable(0);
export const baby_grow = writable(1000);
export const teen_grow = writable(10000);
export const limited = writable(false);
export const banana = writable(0);
export const bananaLimit = writable(0);
export const loading = writable(true);
export const leadboard = writable<UserScore[]>([]);
export const memberCount = writable(0);
export const runtime = writable("");
export const solanaAddress = writable("");


