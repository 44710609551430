<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import bananaIcon from "../assets/banana.png";

  import { loading } from "../store";
  import { leadboard } from "../store";
  import { memberCount } from "../store";

  function fetchLeadBoard() {
    fetch(`https://api.elerp.net/leaderboard/`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        loading.set(false);
        leadboard.set(data.top);
        memberCount.set(data.memberCount);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  onMount(() => {
    fetchLeadBoard();
  });
</script>

<div
  class="flex-col justify-start items-center content-start bg-[#1F1F1F] h-full w-full"
>
  <div class="flex mt-16 content-center items-center justify-center h-[4.5rem]">
    <p class="font-heyaugust text-white text-5xl">Leaderboard</p>
  </div>
  <div class="flex content-center items-center justify-center mb-5">
    <div class=" font-popins text-base text-white">All Addresses</div>
    <div class=" font-popins text-base text-white mx-1">:</div>
    <div class=" font-popins text-base text-primary font-bold">
      {$memberCount}
    </div>
  </div>
  <div class="pb-4">
    {#each $leadboard as { eth_address, score }, index}
      <div class="pl-5 pr-5 mb-2">
        <div
          class="flex bg-yellow h-12 w-full rounded-full justify-between content-center items-center"
        >
          <div class="flex justify-start content-center items-center">
            <div
              class="w-8 h-8 bg-primary rounded-full ml-2 flex content-center items-center justify-center"
            >
              <p class="font-popins italic text-base text-black font-extrabold">
                {index + 1}
              </p>
            </div>
            <div>
              <p class="font-popins italic text-base font-bold ml-1">
                {`${eth_address.substring(0, 5)}...${eth_address.substring(eth_address.length - 3)}`}
              </p>
            </div>
          </div>
          <div class="flex mr-2">
            <p
              class="font-popins italic text-[#222222] font-extrabold text-base mr-1"
            >
              {score.toLocaleString()}
            </p>
            <img src={bananaIcon} alt="banana" class="w-6 h-6" />
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>
