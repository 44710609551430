<script lang="ts">
  export let message: string;
  export let color: string = "bg-primary";
</script>

<div class="relative top-32 flex z-30 content-center justify-center">
  <div
    class="{color}  z-30 h-10 w-auto px-5 rounded-full flex content-center items-center border-2 border-black shadow shadow-black"
  >
    <p class="font-popins font-bold text-base">
      {message}
    </p>
  </div>
</div>
