<script lang="ts">
  import iconWallet from "../assets/icon_wallet.png";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function onClickConnect() {
    dispatch("connectEvent");
  }
</script>

<div class=" w-72 h-72 rounded-3xl bg-primary z-50 p-2">
  <div
    class=" px-8 py-4 w-full h-full border-2 border-black rounded-2xl bg-yellow z-50 flex flex-col content-center items-center justify-center"
  >
    <div class="h-20 w-20">
      <img src={iconWallet} alt="iconWallet" />
    </div>
    <p class="text-black font-popins text-xl text-center font-bold mt-4">
      Please connect your wallet first
    </p>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="h-8 w-36 bg-black flex content-center items-center justify-center rounded-full mt-4"
      on:click={onClickConnect}
    >
      <p class="text-yellow font-popins text-sm font-bold">Connect Wallet</p>
    </div>
  </div>
</div>
