<script lang="ts">
  import { Parser, Player, DB } from "svga";
  import { onMount, afterUpdate } from "svelte";

  import babe_breath from "../assets/svga/babe_breath.svga";
  import babe_eat from "../assets/svga/babe_eat.svga";
  import teen_breath from "../assets/svga/teen_breath.svga";
  import teen_eat from "../assets/svga/teen_eat.svga";
  import master_breath from "../assets/svga/master_breath.svga";
  import master_eat from "../assets/svga/master_eat.svga";
  import babe_teen from "../assets/svga/babe_teen.svga";
  import teen_master from "../assets/svga/teen_master.svga";

  import { score } from "../store";
  import { baby_grow } from "../store";
  import { teen_grow } from "../store";

  let actionA: Player;
  let actionB: Player;
  let actionC: Player;
  let actionD: Player;
  let actionE: Player;
  let actionF: Player;
  let actionG: Player;
  let actionH: Player;
  let showCanvasA = false;
  let showCanvasB = false;
  let showCanvasC = false;
  let showCanvasD = false;
  let showCanvasE = false;
  let showCanvasF = false;
  let showCanvasG = false;
  let showCanvasH = false;
  let feeding = false;
  let eating = false;
  let loading = true;
  let loading_precent = 0;
  let interlude = false;

  onMount(async () => {
    actionA = new Player({
      container: document.getElementById("canvasA") as HTMLCanvasElement,
      loop: 0,
      isCacheFrames: true,
      isOpenNoExecutionDelay: true,
    });
    actionB = new Player({
      container: document.getElementById("canvasB") as HTMLCanvasElement,
      loop: 1,
    });
    actionC = new Player({
      container: document.getElementById("canvasC") as HTMLCanvasElement,
      loop: 1,
    });
    actionD = new Player({
      container: document.getElementById("canvasD") as HTMLCanvasElement,
    });
    actionE = new Player({
      container: document.getElementById("canvasE") as HTMLCanvasElement,
      loop: 1,
    });
    actionF = new Player({
      container: document.getElementById("canvasF") as HTMLCanvasElement,
      loop: 1,
    });
    actionG = new Player({
      container: document.getElementById("canvasG") as HTMLCanvasElement,
    });
    actionH = new Player({
      container: document.getElementById("canvasH") as HTMLCanvasElement,
      loop: 1,
    });
    await buffer(actionA, babe_breath);
    loading_precent = 10;
    await buffer(actionB, babe_eat);
    loading_precent = 20;
    await buffer(actionC, babe_teen);
    loading_precent = 30;
    await buffer(actionD, teen_breath);
    loading_precent = 60;
    await buffer(actionE, teen_eat);
    loading_precent = 70;
    await buffer(actionF, teen_master);
    loading_precent = 80;
    await buffer(actionG, master_breath);
    loading_precent = 90;
    await buffer(actionH, master_eat);
    loading_precent = 100;
    loading = false;
    setGameState();
  });

  export function handleFeed(_score: number) {
    if (interlude) {
      return;
    }
    if (feeding) {
      return;
    }
    if ($score < $baby_grow) {
      setTimeout(() => {
        feeding = false;
      }, 1100);
      playActionB();
    } else if ($score == $baby_grow) {
      interlude = true;
      setTimeout(() => {
        feeding = false;
        interlude = false;
      }, 2100);
      playActionC();
      return;
    } else if ($score < $teen_grow) {
      setTimeout(() => {
        feeding = false;
      }, 1100);
      playActionE();
    } else if ($score == $teen_grow) {
      interlude = true;
      setTimeout(() => {
        interlude = false;
        feeding = false;
      }, 2100);
      playActionF();
    } else if ($score > $teen_grow) {
      setTimeout(() => {
        feeding = false;
      }, 1100);
      playActionH();
    } else {
      setTimeout(() => {
        feeding = false;
      }, 1100);
      playActionA();
    }
    eating = false;
  }

  export function setGameState() {
    console.log("setGameState", $score, $baby_grow, $teen_grow);
    if ($score < $baby_grow) {
      playActionA();
    } else if ($score >= $baby_grow && $score < $teen_grow) {
      playActionD();
    } else if ($score >= $teen_grow) {
      playActionG();
    } else {
      playActionA();
    }
  }

  async function buffer(player: Player, name: string) {
    try {
      const db = new DB();
      let svga = await db.find(name);
      if (!svga) {
        const parser = new Parser({ isDisableImageBitmapShim: true });
        svga = await parser.load(name);
        await db.insert(name, svga);
      }
      await player.mount(svga);
    } catch (error) {
      console.error(error);
    }
  }

  function playActionA() {
    actionA.pause();
    actionA.onStart = () => {
      setTimeout(() => {
        showCanvasA = true;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = false;
        actionB.pause();
      }, 100);
    };
    showCanvasA = true;
    actionA.start();
  }

  function playActionB() {
    showCanvasB = true;
    actionB.pause();
    actionB.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = true;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = false;
        actionA.stop();
      }, 100);
    };
    actionB.onEnd = () => {
      eating = false;
      playActionA();
    };
    actionB.start();
  }

  function playActionC() {
    showCanvasC = true;
    actionC.pause();
    actionC.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = true;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = false;
        actionA.stop();
        actionB.stop();
      }, 300);
    };
    actionC.onEnd = () => {
      eating = false;
      playActionD();
    };
    actionC.start();
  }

  function playActionD() {
    showCanvasD = true;
    actionD.pause();
    actionD.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = true;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = false;
        actionC.stop();
        actionE.stop();
      }, 100);
    };
    actionD.start();
  }

  function playActionE() {
    showCanvasE = true;
    actionE.pause();
    actionE.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = true;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = false;
        actionD.stop();
      }, 100);
    };
    actionE.onEnd = () => {
      eating = false;
      playActionD();
    };
    actionE.start();
  }

  function playActionF() {
    showCanvasF = true;
    actionF.pause();
    actionF.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = true;
        showCanvasG = false;
        showCanvasH = false;
        actionD.stop();
        actionE.stop();
      }, 100);
    };
    actionF.onEnd = () => {
      eating = false;
      playActionG();
    };
    actionF.start();
  }

  function playActionG() {
    showCanvasG = true;
    actionG.pause();
    actionG.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = true;
        showCanvasH = false;
        actionF.pause();
        actionH.pause();
      }, 100);
    };
    actionG.start();
  }

  function playActionH() {
    showCanvasH = true;
    actionH.pause();
    actionH.onStart = () => {
      setTimeout(() => {
        showCanvasA = false;
        showCanvasB = false;
        showCanvasC = false;
        showCanvasD = false;
        showCanvasE = false;
        showCanvasF = false;
        showCanvasG = false;
        showCanvasH = true;
        actionG.pause();
      }, 100);
    };
    actionH.onEnd = () => {
      eating = false;
      playActionG();
    };
    actionH.start();
  }
</script>

<div class="">
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasA
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasA" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasB
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasB" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasC
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasC" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasD
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasD" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasE
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasE" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasF
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasF" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasG
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasG" />
  </div>
  <div
    class="absolute z-20 bottom-0 left-0 w-full {showCanvasH
      ? 'visible'
      : 'invisible'}"
  >
    <canvas class="w-full h-full" id="canvasH" />
  </div>
</div>
